import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  darken,
} from "@mui/material";
import React from "react";
import { ThemeButton } from "../ThemeButton";

// const attention = {
//   title: '¡ATENCION!',
//   text1: 'Tenemos una tarifa que se ajusta mejor a tus necesidades y que recompensa tu fidelidad.',
//   text2: 'Tu operador no te ofrece su mejor tarifa disponible ni recompensa tu fidelidad.',
//   button: "QUIERO MI OFERTA PERSONALIZADA"
// }

export default function CardComponent({
  item,
  type,
  disable,
  setDisable,
  sx,
  sendMail,
  offerEmail,
  setOfferEmail,
  isOfferEmailValid,
  handleEnd,
  handleOffer,
  question,
  clickNow,
  themeColor,
  index,
  setValue,
  question_input_data,
  setQuestion_input_data,
  answerKey,
  value,
  isValidEmail,
  isValidName,
  isValidPhone,
  isPostCode,
  attentionText
}) {
  const renderQuestionWithBreaks = (text) => {
    return text.split("\n\r").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      {type === "confirm" ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "-webkit-fill-available",
            position: "absolute",
          }}
          zIndex={101}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#00000061",
              // px: { md: 3, xs: 1.5 },
              // py: 3,
              mx: 5,
              mt: "80px",
              mb: 5,
              borderRadius: '12px',
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              border: "5px solid #fff"
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                px: { md: 7, xs: 0 },
                "& .MuiTypography-root": { lineHeight: 1.2, fontWeight: 600, color: "#fff" },
              }}
            >
              <Typography variant="h6">{attentionText?.title || ""}</Typography>
              <Typography sx={{ pb: 5 }} variant="h6">
                {attentionText?.text1 || ""}
              </Typography>
              <Typography variant="h6">
                {attentionText?.text2 || ""}
              </Typography>
            </Box>
            <Box>
              <Button
                size="large"
                onClick={() =>
                  clickNow('save', index)
                }
                sx={{
                  backgroundColor: themeColor,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: darken(themeColor, 0.2),
                  },
                }}
                variant="contained"
              >
                {attentionText?.button || ""}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            position: "absolute",
            bottom: "0px",
            display: "flex",
            alignItems: "flex-end",
          }}
          zIndex={101}
        >
          <Box sx={{ width: "100%", px: { md: 3, xs: 1.5 }, pb: 3 }}>
            <Box
              sx={{
                backgroundColor: "white",
                height: "100%",
                overflow: "hidden",
                borderRadius: "12px",
                ...sx,
              }}
            >
              <header
                style={{
                  padding: "16px",
                  backgroundColor: themeColor,
                  fontWeightRegular: 500,
                  textAlign: "center",
                  fontSize: "19px",
                  color: "#ffff",
                }}
              >
                {renderQuestionWithBreaks(question)}
              </header>
              {type === "question" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: { md: 2, xs: 1.5 },
                    p: { md: 3, xs: 2 },
                  }}
                >
                  {item.answers.map((ans, index1) => (
                    <ThemeButton
                      themeColor={themeColor}
                      key={index1}
                      onClick={() => clickNow(ans, index, item.answerKey)}
                      iconIndex={ans.count}
                    >
                      {ans.desc}
                    </ThemeButton>
                  ))}
                </Box>
              ) : (
                <></>
              )}

              {type === "question_input" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: { md: 2, xs: 1.5 },
                    p: { md: 3, xs: 2 },
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        "&:hover fieldset": {
                          borderColor: themeColor,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: themeColor,
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: `${themeColor} !important`,
                      },
                      "& .Mui-focused .MuiFormLabel-root": {
                        color: themeColor,
                      },
                    }}
                    type="text"
                    value={question_input_data[item.answerKey]}
                    onChange={(e) =>
                      setQuestion_input_data({
                        ...question_input_data,
                        [item.answerKey]: e.target.value,
                      })
                    }
                    label="Escriba su respuesta aqui"
                  />
                  <Button
                    disabled={!question_input_data[item.answerKey]}
                    size="large"
                    onClick={() =>
                      clickNow(question_input_data[item.answerKey], index)
                    }
                    sx={{
                      backgroundColor: themeColor,
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: darken(themeColor, 0.2),
                      },
                      "&:disabled": {
                        backgroundColor: "#ccc",
                        color: "#000",
                      },
                    }}
                    variant="contained"
                  >
                    SIGUIENTE
                  </Button>
                </Box>
              ) : (
                <></>
              )}

              {type === "query" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    p: 2,
                  }}
                >
                  <Box sx={{ px: { md: 2, xs: 0 } }}>
                    <Typography sx={{ textAlign: "center" }}>
                      Hemos encontrado ofertas que le pueden encajar, solicita
                      información sin compromiso
                    </Typography>
                  </Box>
                  {item.answerKey === "name" && (
                    <>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "&:hover fieldset": {
                              borderColor: themeColor, // Change to your desired hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: themeColor, // Change to your desired focused color
                            },
                          },
                          "& .MuiFormLabel-root": {
                            color: `${themeColor} !important`, // Change to your desired label color
                          },
                          "& .Mui-focused .MuiFormLabel-root": {
                            color: themeColor, // Change to your desired focused label color
                          },
                        }}
                        type="text"
                        value={value.name}
                        error={!isValidName}
                        helperText={
                          !isValidName && "Por favor ingrese un nombre..."
                        }
                        onChange={(e) =>
                          setValue({ ...value, name: e.target.value })
                        }
                        label="Nombre y apellidos"
                      />
                      {item.verify === 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: themeColor,
                                "&.Mui-checked": {
                                  color: themeColor,
                                },
                              }}
                              onChange={(e) => setDisable(e.target.checked)}
                            />
                          }
                          label={
                            <span>
                              Acepto la{" "}
                              <Link
                                href="https://golden-single-ae3.notion.site/Pol-tica-de-privacidad-80b380589637470faf8b90e5ff7ff6a1?pvs=4"
                                target="_blank"
                                sx={{
                                  textDecoration: "none",
                                  color: themeColor,
                                }}
                              >
                                política de privacidad
                              </Link>
                            </span>
                          }
                        />
                      )}

                      <Button
                        disabled={!disable && item.verify === 1}
                        size="large"
                        onClick={(e) =>
                          clickNow(value.name, index, item.answerKey)
                        }
                        sx={{
                          backgroundColor: themeColor, // Set the background color to the theme color
                          color: "#fff", // Set the text color to white
                          "&:hover": {
                            backgroundColor: darken(themeColor, 0.2), // Darken the background color on hover
                          },
                          "&:disabled": {
                            backgroundColor: "#ccc", // Set a lighter color when the button is disabled
                            color: "#000", // Set the text color to black when disabled
                          },
                        }}
                        variant="contained"
                      >
                        SIGUIENTE
                      </Button>
                    </>
                  )}

                  {item.answerKey === "email" && (
                    <>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "&:hover fieldset": {
                              borderColor: themeColor, // Change to your desired hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: themeColor, // Change to your desired focused color
                            },
                          },
                          "& .MuiFormLabel-root": {
                            color: `${themeColor} !important`, // Change to your desired label color
                          },
                          "& .Mui-focused .MuiFormLabel-root": {
                            color: themeColor, // Change to your desired focused label color
                          },
                        }}
                        type="email"
                        value={value.email}
                        error={!isValidEmail}
                        helperText={
                          !isValidEmail &&
                          "Por favor, introduce una dirección de correo electrónico válida..."
                        }
                        onChange={(e) =>
                          setValue({ ...value, email: e.target.value })
                        }
                        label="Email"
                      />
                      {item.verify === 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: themeColor,
                                "&.Mui-checked": {
                                  color: themeColor,
                                },
                              }}
                              onChange={(e) => setDisable(e.target.checked)}
                            />
                          }
                          label={
                            <span>
                              Acepto la{" "}
                              <Link
                                href="https://golden-single-ae3.notion.site/Pol-tica-de-privacidad-80b380589637470faf8b90e5ff7ff6a1?pvs=4"
                                target="_blank"
                                sx={{
                                  textDecoration: "none",
                                  color: themeColor,
                                }}
                              >
                                política de privacidad
                              </Link>
                            </span>
                          }
                        />
                      )}
                      <Button
                        disabled={!disable && item.verify === 1}
                        size="large"
                        onClick={() =>
                          clickNow(value.email, index, item.answerKey)
                        }
                        sx={{
                          backgroundColor: themeColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: darken(themeColor, 0.2),
                          },
                        }}
                        variant="contained"
                      >
                        SIGUIENTE
                      </Button>
                    </>
                  )}

                  {item.answerKey === "phone" && (
                    <>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "&:hover fieldset": {
                              borderColor: themeColor, // Change to your desired hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: themeColor, // Change to your desired focused color
                            },
                          },
                          "& .MuiFormLabel-root": {
                            color: `${themeColor} !important`, // Change to your desired label color
                          },
                          "& .Mui-focused .MuiFormLabel-root": {
                            color: themeColor, // Change to your desired focused label color
                          },
                        }}
                        type="number"
                        value={value.phone}
                        error={!isValidPhone}
                        helperText={
                          !isValidPhone &&
                          "Por favor ingrese un número de teléfono válido..."
                        }
                        onChange={(e) =>
                          setValue({ ...value, phone: e.target.value })
                        }
                        label="Teléfono"
                      />
                      {item.verify === 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: themeColor,
                                "&.Mui-checked": {
                                  color: themeColor,
                                },
                              }}
                              onChange={(e) => setDisable(e.target.checked)}
                            />
                          }
                          label={
                            <span>
                              Acepto la{" "}
                              <Link
                                href="https://golden-single-ae3.notion.site/Pol-tica-de-privacidad-80b380589637470faf8b90e5ff7ff6a1?pvs=4"
                                target="_blank"
                                sx={{
                                  textDecoration: "none",
                                  color: themeColor,
                                }}
                              >
                                política de privacidad
                              </Link>
                            </span>
                          }
                        />
                      )}
                      <Button
                        size="large"
                        disabled={!disable && item.verify === 1}
                        onClick={() =>
                          clickNow(value.phone, index, item.answerKey)
                        }
                        sx={{
                          backgroundColor: themeColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: darken(themeColor, 0.2),
                          },
                        }}
                        variant="contained"
                      >
                        SIGUIENTE
                      </Button>
                    </>
                  )}

                  {item.answerKey === "postCode" && (
                    <>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "&:hover fieldset": {
                              borderColor: themeColor, // Change to your desired hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: themeColor, // Change to your desired focused color
                            },
                          },
                          "& .MuiFormLabel-root": {
                            color: `${themeColor} !important`, // Change to your desired label color
                          },
                          "& .Mui-focused .MuiFormLabel-root": {
                            color: themeColor, // Change to your desired focused label color
                          },
                        }}
                        type="number"
                        value={value.postCode}
                        error={!isPostCode}
                        helperText={
                          !isPostCode &&
                          "Por favor ingrese un Código postal válido..."
                        }
                        onChange={(e) =>
                          setValue({ ...value, postCode: e.target.value })
                        }
                        label="Código postal"
                      />
                      {item.verify === 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: themeColor,
                                "&.Mui-checked": {
                                  color: themeColor,
                                },
                              }}
                              onChange={(e) => setDisable(e.target.checked)}
                            />
                          }
                          label={
                            <span>
                              Acepto la{" "}
                              <Link
                                href="https://golden-single-ae3.notion.site/Pol-tica-de-privacidad-80b380589637470faf8b90e5ff7ff6a1?pvs=4"
                                target="_blank"
                                sx={{
                                  textDecoration: "none",
                                  color: themeColor,
                                }}
                              >
                                política de privacidad
                              </Link>
                            </span>
                          }
                        />
                      )}
                      <Button
                        size="large"
                        disabled={!disable && item.verify === 1}
                        onClick={() =>
                          clickNow(value.postCode, index, item.answerKey)
                        }
                        sx={{
                          backgroundColor: themeColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: darken(themeColor, 0.2),
                          },
                        }}
                        variant="contained"
                      >
                        SIGUIENTE
                      </Button>
                    </>
                  )}

                  {item.answerKey === "schedule" && (
                    <>
                      <Box
                        sx={{
                          border: "1px solid #e0e0e0",
                          borderRadius: "10px",
                          position: "relative",
                          pl: 2,
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          sx={{
                            position: "absolute",
                            color: `${themeColor} !important`,
                            backgroundColor: "#fff",
                            top: -10,
                          }}
                        >
                          Elige horario de llamada
                        </Typography>
                        <RadioGroup
                          sx={{
                            maxHeight: "85px",
                            overflowY: "auto",
                            flexWrap: "nowrap",
                            "&::-webkit-scrollbar": {
                              height: "7px",
                              width: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#888",
                              borderRadius: "12px",
                            },
                          }}
                          value={value.schedule}
                          onChange={(e) =>
                            setValue({ ...value, schedule: e.target.value })
                          }
                        >
                          <FormControlLabel
                            value="10:00-14:00"
                            control={<Radio />}
                            label="10:00 - 14:00"
                          />
                          <FormControlLabel
                            value="16:00-21:00"
                            control={<Radio />}
                            label="16:00 - 21:00"
                          />
                        </RadioGroup>
                      </Box>
                      {item.verify === 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: themeColor,
                                "&.Mui-checked": {
                                  color: themeColor,
                                },
                              }}
                              onChange={(e) => setDisable(e.target.checked)}
                            />
                          }
                          label={
                            <span>
                              Acepto la{" "}
                              <Link
                                href="https://golden-single-ae3.notion.site/Pol-tica-de-privacidad-80b380589637470faf8b90e5ff7ff6a1?pvs=4"
                                target="_blank"
                                sx={{
                                  textDecoration: "none",
                                  color: themeColor,
                                }}
                              >
                                política de privacidad
                              </Link>
                            </span>
                          }
                        />
                      )}
                      <Button
                        disabled={!disable && item.verify === 1}
                        size="large"
                        onClick={() =>
                          clickNow(value.schedule, index, item.answerKey)
                        }
                        sx={{
                          backgroundColor: themeColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: darken(themeColor, 0.2),
                          },
                        }}
                        variant="contained"
                      >
                        ME INTERESA
                      </Button>
                    </>
                  )}
                </Box>
              ) : (
                <></>
              )}

              {type === "offerType" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    p: 2,
                  }}
                >
                  {sendMail ? (
                    <>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            "&:hover fieldset": {
                              borderColor: themeColor, // Change to your desired hover color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: themeColor, // Change to your desired focused color
                            },
                          },
                          "& .MuiFormLabel-root": {
                            color: `${themeColor} !important`, // Change to your desired label color
                          },
                          "& .Mui-focused .MuiFormLabel-root": {
                            color: themeColor, // Change to your desired focused label color
                          },
                        }}
                        error={isOfferEmailValid}
                        type="email"
                        value={offerEmail}
                        helperText={
                          isOfferEmailValid &&
                          "Por favor, introduce una dirección de correo electrónico válida..."
                        }
                        onChange={(e) => setOfferEmail(e.target.value)}
                        label="Email"
                      />
                      <Button
                        size="large"
                        onClick={sendMail}
                        sx={{
                          backgroundColor: themeColor,
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: darken(themeColor, 0.2),
                          },
                        }}
                        variant="contained"
                      >
                        SIGUIENTE
                      </Button>
                    </>
                  ) : (
                    <>
                      <ThemeButton
                        themeColor={themeColor}
                        onClick={handleEnd}
                        iconIndex={"A"}
                      >
                        Si
                      </ThemeButton>
                      <ThemeButton
                        themeColor={themeColor}
                        onClick={handleOffer}
                        iconIndex={"B"}
                      >
                        No
                      </ThemeButton>
                    </>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
