export const saveData = async (data) => {
  try {
    const response = await fetch(
      "https://europe-west3-zoa-suite.cloudfunctions.net/landing-form",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();

    return { status: "success", data: responseData };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const saveUserVisitData = async (data) => {
  try {
    const response = await fetch(
      "https://europe-west3-zoa-suite.cloudfunctions.net/campign-data",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return { status: "success", msg: response.json() };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const getQuestionData = async (id) => {
  return fetch(
    "https://europe-west3-zoa-suite.cloudfunctions.net/landing-form-client/?id=" +
    id,
    {
      method: "GET",
    }
  )
    .then((res) => res.json())
    .then((data) => {
      return { success: true, data: data };
    })
    .catch((err) => {
      return { success: false };
    });
};
