import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Mainpage } from './pages/Mainpage';
import { Success } from './pages/Success';
import './App.css';
import logo from './logo.svg';
import FacebookPixel from './tracking/FacebookPixel';
import { DefaultMainPage } from './pages/defaltMainPage';
import { AuthProvider } from './context/authContext';
import TikTokPixel from './tracking/tiktok';

function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <FacebookPixel />
          <TikTokPixel />
          <Routes>
            <Route path="/" element={<DefaultMainPage />} />
            <Route path="/:id" element={<Mainpage />} />
            <Route path="/success" element={<Success />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
