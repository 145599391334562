import { Button, Card, CardContent, Dialog, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/material"
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getQuestionData } from "../../service/saveData";


export function Success() {
    // const [showMassage, setShowMassage] = useState(false);
    // const [open, setOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    console.log("htmlContent: ", htmlContent);
    const location = useLocation();
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get('');


    // const timeStamp = new Date();

    const getData = async () => {
        const result = await getQuestionData(id);
        if (result.success) {
            setHtmlContent(result.data.success_html_jsx)
        }
    }

    useEffect(() => {
        if (id) {
            getData(id)
        }
    }, [id])

    // const isBusinessHours = () => {
    //     const now = new Date();
    //     const day = now.getDay();
    //     const hour = now.getHours();
    //     return day >= 1 && day <= 5 && hour >= 9 && hour < 20;
    // };

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleSave = () => {
    //     console.log(isBusinessHours(), "isBusinessHours")
    //     setShowMassage(true);
    // }

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            {/* <Box sx={{
                position: "relative",
                overflow: 'hidden',
                backgroundColor: "#f2f2f2",
                width: '100%',
                height: '100vh',
            }}> */}
            {/* {showMassage ?
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        backgroundColor: "#f0f0f0"
                    }}>
                        <Card sx={{
                            width: "300px",
                            borderRadius: "35px",
                            boxShadow: "none",
                            border: "1px solid lightgray",
                            textAlign: "center",
                        }}>
                            <CardContent sx={{ paddingBlock: 5 }}>
                                {isBusinessHours() ?
                                    <>
                                        <Typography variant="body1" pb={2}>
                                            No se preocupe, avisamos a nuestros compañeros y en unos minutos le llaman.                                        </Typography>
                                        <Typography variant="body1" pb={2}>
                                            También puedes contactarnos por whatsapp que lo atendemos más rápido.
                                        </Typography>
                                        <Box>
                                            <Button
                                                href="https://wa.me/+34614329287/?text=Hola+quiero+saber+mas+de+Zoa"
                                                sx={{
                                                    borderRadius: '25px',
                                                    textTransform: "none",
                                                    background: '#14c348',
                                                    "&:hover": {
                                                        backgroundColor: "#14c348"
                                                    }
                                                }}
                                                color="success"
                                                variant="contained"
                                                startIcon={<WhatsAppIcon />}
                                            >
                                                Escribenos un Whatsapp
                                            </Button>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Typography variant="body1" pb={2}>
                                            Nuestro horario de atención es de <b>Lunes a Viernes de 9:00h a 20:00h.</b>
                                        </Typography>
                                        <Typography variant="body1">
                                            Va a recibir ahora un <b>Whatsapp</b> donde nos puede indicar cuando le vendría mejor que le llamemos.
                                        </Typography>
                                    </>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                    :
                    <> */}
            {/* <video
                            id="bg"
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover", position: "absolute" }}
                            autoPlay
                            muted
                            controls={false}
                            playsInline
                            loop
                        >
                            <source
                                src="https://s3-eu-west-1.amazonaws.com/storage-playfilm-public/media/3/users/19048/resources/2231109/clips/city6_qua_standar.mp4"
                                type="video/mp4"
                            />
                            <source
                                src="https://www.w3schools.com/html/mov_bbb.ogg"
                                type="video/ogg"
                            />
                        </video> */}

            {/* <Box id="trama-image" /> */}

            {/* <Box sx={{
                    width: "100%",
                    maxWidth: '720px',
                    marginInline: "auto",
                    height: "100vh",
                    display: 'flex',
                    flexDirection: 'column',
                    // boxShadow: "0px 22px 30px #000",
                    backgroundColor: "#fff",
                    zIndex: 100,
                    position: "relative",
                }}>
                    <Box sx={{
                        height: "60%",
                        width: "100%",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: "flex-end" }}>
                            <Box sx={{ width: '100%', display: 'flex', px: { xs: 2, md: 8 }, py: 4 }}>
                                <Box sx={{ width: '100%', border: '3px solid gray', padding: '40px 0px', marginInline: "auto" }}>
                                    <Typography sx={{ textAlign: 'center', pb: 3, fontSize: '18px', fontWeight: 400 }}>¡Gracias por sus datos!👍🏼</Typography>
                                    <Typography sx={{ textAlign: 'center', pb: 3, fontSize: '18px', fontWeight: 400 }}>Te acabamos de enviar un mensaje de <b>Whatsapp<Box sx={{ width: "20px", mb: "-5px", ml: "5px" }} src="/images/whatsapp-logo.png" component={"img"} /></b>  con toda la información.</Typography>
                                    <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 400 }}><b>Revisalo🧐</b>, y te contamos las ofertas estrella 🎖️ de esta semana .</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box> */}
            {/* <Box sx={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                px: { xs: 2, md: 8 },
                                py: 5,
                            }}>
                                <Button
                                    color="error"
                                    sx={{
                                        backgroundColor: "#fce5cd",
                                        color: "black",
                                        fontWeight: 600,
                                        mb: 2,
                                    }}
                                    fullWidth
                                    variant="text"
                                    onClick={handleOpen}
                                >
                                    ¿Quieres ajustar tu tarifa al máximo?
                                </Button>
                                <Typography py={2}>O</Typography>
                                <Button
                                    color="success"
                                    onClick={handleSave}
                                    sx={{
                                        backgroundColor: "#d9ead3",
                                        color: "black",
                                        fontWeight: 600,
                                    }}
                                    fullWidth
                                    variant="text"
                                >
                                    ¿Quieres que te llamemos ahora?
                                </Button>
                            </Box> */}
            {/* </Box> */}
            {/* </>
                } */}

            {/* </Box> */}
            {/* <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent>
                    <iframe
                        height="430"
                        width="350"
                        src="https://console.dialogflow.com/api-client/demo/embedded/0baff65e-774a-4bc3-be49-cab51223baee"
                        title="Dialogflow Bot"
                        style={{ border: 'none' }}
                    ></iframe>
                </DialogContent>
            </Dialog> */}
        </>
    );
}