import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const loaderStyle = {
    position: "relative",
    width: "64px",
    height: "64px",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "4px",
};

const dotAnimations = [
    { animationDelay: "0s" },
    { animationDelay: "0.1s" },
    { animationDelay: "0.2s" },
    { animationDelay: "0.3s" },
    { animationDelay: "0.4s" },
    { animationDelay: "0.5s" },
    { animationDelay: "0.6s" },
    { animationDelay: "0.7s" },
    { animationDelay: "0.8s" },
    { animationDelay: "0.9s" },
    { animationDelay: "1s" },
    { animationDelay: "1.1s" },
];

export default function PageLoader({ loadingMsg, themeColor }) {

    const dotStyle = {
        width: "16px",
        height: "16px",
        backgroundColor: themeColor,
        borderRadius: "50%",
        animation: "bounce 1.2s infinite ease-in-out both"
    };


    return (
        <Box sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                rowGap: 3
            }}>
                <Box sx={loaderStyle} className="loader--default">
                    {dotAnimations.map((animation, index) => (
                        <Box key={index} sx={{ ...dotStyle, ...animation }} />
                    ))}
                </Box>
                <Typography
                    color="GrayText"
                    variant="inherit"
                    sx={{ fontWeight: 500, px: 4, textAlign: "center" }}
                    aria-live="polite"
                >
                    {loadingMsg}
                    {/* Gracias por tu paciencia. Estamos buscando la oferta que mejor se adapte a tus necesidades… */}
                </Typography>
            </Box>
        </Box >
    )
}
