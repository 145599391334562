import Box from "@mui/material/Box";
import { useEffect, useState, useRef, useCallback } from "react";
import { IconButton, Typography } from "@mui/material";
import CardComponent from "../../component/card";
import { saveUserVisitData, saveData } from "../../service/saveData";
import PageLoader from "../../component/pageLoader/pageLoader";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const themeColor = "#37d4fd";

export function DefaultMainPage() {
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userDocumentId, setUserDocumentId] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isPostCode, setIsPostCode] = useState(true);
  const [browserHeight, setBrowserHeight] = useState(window.innerHeight);
  const [muted, setMuted] = useState(true);
  const [video, setVideo] = useState(
    "https://s3-eu-west-1.amazonaws.com/storage-playfilm-public/media/3/users/19048/resources/2231109/clips/city6_qua_standar.mp4"
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const videoRef = useRef(null);

  // const [q0_1, setQ0_1] = useState("");
  // const [q0_2, setQ0_2] = useState("");
  // const [q0_3, setQ0_3] = useState("");

  const [ip, setIp] = useState("");
  const [landingCode, setLandingCode] = useState("");
  const [adName, setAdName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [addId, setAddId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [disable, setDisable] = useState(false);
  const [changeTimeout, setChangeTimeout] = useState(false);

  const [offerStep, setOfferStep] = useState(1);
  const [privacyCheckbox, setPrivacyCheckbox] = useState("No");
  console.log("privacyCheckbox: ", privacyCheckbox);
  const [offerEmail, setOfferEmail] = useState("");
  const [isOfferEmailValid, setIsOfferEmailValid] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(
    "¡Hola! 👋🏼  Qué bueno verte por aquí"
  );

  useEffect(() => {
    const campaign_id = searchParams.get("campaign_id");
    const ad_id = searchParams.get("ad_id");
    const landing_code = searchParams.get("landing_code");
    const ad_name = searchParams.get("ad_name");
    setLandingCode(landing_code);
    setAdName(ad_name);
    setAddId(ad_id);
    setCampaignId(campaign_id);
  }, [searchParams]);

  useEffect(() => {
    if (disable) {
      setPrivacyCheckbox("Yes");
    } else {
      setPrivacyCheckbox("No");
    }
  }, [disable]);

  const getIpAddress = () => {
    fetch("https://api.ipify.org?format=json", {
      method: "GET",
      headers: {},
    })
      .then((res) => res.json())
      .then(async (data) => {
        setIp(data.ip);
      });
  };

  useEffect(() => {
    getIpAddress();
    setStartDate(new Date());
  }, []);

  const SaveCampaign = async () => {
    const userData = getUserData();
    const { q0_1, privacyCheckbox, ...saveData } = userData;
    const result = await saveUserVisitData(saveData);
  };

  useEffect(() => {
    if (ip && searchParams) {
      SaveCampaign();
    }
  }, [ip, searchParams]);

  const [data, setData] = useState({});
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    postCode: "",
    schedule: "",
  });

  useEffect(() => {
    const handleResize = () => {
      let heightWithoutUrlBar = window.innerHeight;

      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isIOS) {
        const isUrlBarVisible =
          window.innerHeight === document.documentElement.clientHeight;
        if (isUrlBarVisible) {
          heightWithoutUrlBar = document.documentElement.clientHeight;
        }
      }

      setBrowserHeight(heightWithoutUrlBar);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Category = [
    {
      question: "¿Ahora, en que compañía de telefonía estas?",
      type: "question",
      answers: [
        { count: "A", desc: "Vodafone/Lowi/Fi Network" },
        { count: "B", desc: "Movistar/O2/Digi" },
        { count: "C", desc: "Yoigo/Masmovil" },
        { count: "D", desc: "Orange/Jazzte/Simyo" },
      ],
    },
    {
      question: "¿Tienes permanencia en tu actual compañía?",
      type: "question",
      answers: [
        { count: "A", desc: "Si, y me queda mas de 3 meses" },
        { count: "B", desc: "Si,pero ya me queda menos de 3 meses" },
        { count: "C", desc: "No" },
      ],
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "question",
      answers: [
        { count: "A", desc: "Solo móvil" },
        { count: "B", desc: "Solo fibra" },
        { count: "C", desc: "Fibra y móvil" },
        { count: "D", desc: "Fibra, móvil y tele" },
      ],
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "name",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "email",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "phone",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "postCode",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      type: "query",
      answerKey: "schedule",
    },
    {
      question: "Rellena el formulario y nos pondremos en contacto contigo",
      answerKey: "confirmation",
      type: "confirm",
    },
  ];
  const clickNow = async (ans, index) => {
    if (index === 0) {
      setActive(index + 1);
      setData({ ...data, q1: ans.desc });
    } else if (index === 1) {
      setActive(index + 1);
      setData({ ...data, q2: ans.desc });
    } else if (index === 2) {
      // setLoadingMsg(
      //   "Gracias por tu paciencia. Estamos buscando la oferta que mejor se adapte a tus necesidades…"
      // );
      setActive(index + 1);
      setData({ ...data, q3: ans.desc });
      // setLoading(true);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 4000);
    } else if (index === 3) {
      if (value.name) {
        setActive(index + 1);
        setData({ ...data, name: value.name });
      } else {
        setIsValidName(false);
      }
    } else if (index === 4) {
      if (
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value.email).toLowerCase())
      ) {
        setActive(index + 1);
        setData({ ...data, email: value.email });
      } else {
        setIsValidEmail(false);
      }
    } else if (index === 5) {
      if (value.phone) {
        setActive(index + 1);
        setData({ ...data, telephone: value.phone });
      } else {
        setIsValidPhone(false);
      }
    } else if (index === 6) {
      if (value.postCode) {
        setActive(index + 1);
        setData({ ...data, postCode: value.postCode });
      } else {
        setIsPostCode(false);
      }
    } else if (index === 7) {
      setActive(index + 1);
    } else if (index === 8) {
      await saveUserData();
    }
  };

  const saveUserData = async () => {
    if (value.schedule) {
      setLoadingMsg("Estamos enviando su información 🛫");
      setLoading(true);
      const userData = {
        // q0_1: q0_1,
        push: "m",
        // ...(q0_2 && { q0_2: q0_2 }),
        // ...(q0_3 && { q0_3: q0_3 }),
        ...data,
        schedule: value.schedule,
        privacyCheckbox: privacyCheckbox,
        IP: ip,
        start_time: startDate,
        end_time: new Date(),
        ...(campaignId && { campaign_id: campaignId }),
        ...(addId && { ad_id: addId }),
        ...(adName && { ad_name: adName }),
        ...(landingCode && { landing_code: landingCode }),
        ...(userDocumentId && { user_document_id: userDocumentId }),
      };
      const result = await saveData(userData);
      if (result.status === "success") {
        setUserDocumentId(result.data.id);
        // if (window.fbq) {
        //   window.fbq("track", "CompleteRegistration");
        // }
        setLoading(false);
        console.log("result: ", result);
        navigate("/success");
      } else {
        console.log("result: ", result);
        setLoading(false);
      }
    }
  };

  const getUserData = useCallback(() => {
    return {
      // q0_1: q0_1,
      // ...(q0_2 && { q0_2: q0_2 }),
      // ...(q0_3 && { q0_3: q0_3 }),
      push: "a",
      ...data,
      ...(value.schedule && { schedule: value.schedule }),
      privacyCheckbox: privacyCheckbox,
      IP: ip,
      start_time: startDate,
      ...(campaignId && { campaign_id: campaignId }),
      ...(addId && { ad_id: addId }),
      ...(adName && { ad_name: adName }),
      ...(landingCode && { landing_code: landingCode }),
      ...(userDocumentId && { user_document_id: userDocumentId }),
    };
  }, [
    data,
    value.schedule,
    privacyCheckbox,
    ip,
    startDate,
    campaignId,
    addId,
    adName,
    landingCode,
    userDocumentId,
  ]);

  // Function to auto save data
  const autoSave = useCallback(async () => {
      console.log("data saved");
      const userData = getUserData();
      console.log("userData: ", userData);

      try {
          const result = await saveData(userData);
          if (result.status === "success") {
              setUserDocumentId(result.data.id);
              // if (window.fbq) {
              //     window.fbq("track", "CompleteRegistration");
              // }
              console.log("Save Data Successfully");
          } else {
              console.log("Error saving data:", result.error);
          }
      } catch (error) {
          console.error("Error in autoSave function:", error);
      }
  }, [getUserData, saveData]);

  // Effect to handle timeout based on q0_1 change

  useEffect(() => {
      let timeout;
      if (startDate) {
          timeout = setTimeout(() => {
              setChangeTimeout(true);
          }, 300000);
      }

      return () => {
          if (timeout) {
              clearTimeout(timeout);
          }
      };
  }, [startDate]);

  useEffect(() => {
      if (changeTimeout) {
          autoSave();
          setChangeTimeout(false);
      }
  }, [changeTimeout, autoSave]);

  useEffect(() => {
      const handleBeforeUnload = (event) => {
          event.preventDefault();
          autoSave();
          event.returnValue = "";
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
      };
  }, [autoSave]);

  // const handleOffer = () => {
  //     setLoadingMsg(
  //         "Vale, vamos hacerte 3 preguntas para conseguirte la mejor oferta…"
  //     );
  //     if (offerStep === 1) {
  //         setOfferStep(2);
  //         setQ0_1("no");
  //     } else if (offerStep === 2) {
  //         setOfferStep(3);
  //         setQ0_2("no");
  //     } else if (offerStep === 3) {
  //         if (
  //             offerEmail &&
  //             /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(offerEmail).toLowerCase())
  //         ) {
  //             setOfferStep("end");
  //             setQ0_3(offerEmail);
  //             setLoading(true);
  //             setTimeout(() => {
  //                 setLoading(false);
  //             }, 3000);
  //         } else {
  //             setIsOfferEmailValid(true);
  //         }
  //     }
  // };

  // const handleEnd = () => {
  //     setLoadingMsg(
  //         "Vale, vamos hacerte 3 preguntas para conseguirte la mejor oferta…"
  //     );
  //     if (offerStep === 1) {
  //         setOfferStep("end");
  //         setLoading(true);
  //         setTimeout(() => {
  //             setLoading(false);
  //         }, 3000);
  //         setQ0_1("yes");
  //     } else if (offerStep === 2) {
  //         setOfferStep("end");
  //         setLoading(true);
  //         setTimeout(() => {
  //             setLoading(false);
  //         }, 3000);
  //         setQ0_2("yes");
  //     }
  // };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: { md: "100vh", xs: `${browserHeight}px` },
        }}
      >
        {/* {offerStep === "end" ?
                    <> */}
        <video
          id="bg"
          width="100%"
          height="100%"
          style={{ objectFit: "cover", position: "absolute" }}
          autoPlay
          muted
          controls={false}
          playsInline
          loop
          ref={videoRef} // Attach the ref here
        >
          <source src={video} type="video/mp4" />
          <source
            src="https://www.w3schools.com/html/mov_bbb.ogg"
            type="video/ogg"
          />
        </video>

        <Box id="trama-image" />

        <Box
          sx={{
            width: "100%",
            maxWidth: "720px",
            marginInline: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: " 0px 22px 30px #000",
          }}
        >
          <Box sx={{ flexGrow: 1, position: "relative" }}>
            <video
              width="100%"
              height="100%"
              style={{ objectFit: "cover", position: "absolute" }}
              autoPlay
              muted={muted}
              controls={false}
              playsInline
              loop
              ref={videoRef}
            >
              <source src={video} type="video/mp4" />
            </video>

            {/* {offerStep === "end" ? (
                            <> */}
            {Category.map((item, index) => (
              <>
                {index === active && (
                  <CardComponent
                    isValidEmail={isValidEmail}
                    isValidName={isValidName}
                    isValidPhone={isValidPhone}
                    isPostCode={isPostCode}
                    index={index}
                    themeColor={themeColor}
                    setValue={setValue}
                    value={value}
                    clickNow={clickNow}
                    disable={disable}
                    setDisable={setDisable}
                    item={item}
                    type={item.type}
                    question={item.question}
                  />
                )}

                {item.type === "query" && index === active && (
                  <Typography
                    sx={{
                      position: "absolute",
                      top: 120,
                      right: 0,
                      left: 0,
                      marginInline: "auto",
                      textAlign: "center",
                      color: "white",
                      zIndex: 100,
                    }}
                  >
                    Muchas gracias por tus respuestas
                  </Typography>
                )}
              </>
            ))}
            {/* </>
                        ) : (
                            <>
                                {offerStep === 1 && (
                                    <CardComponent
                                        sx={{
                                            marginBottom: 5,
                                            border: "1px solid #d6d5d5",
                                        }}
                                        themeColor={themeColor}
                                        question="¿Te gustaria conocer una oferta diseñada exclusivamente para ti?"
                                        type={"offerType"}
                                        handleEnd={handleEnd}
                                        handleOffer={handleOffer}
                                    />
                                )}

                                {offerStep === 2 && (
                                    <CardComponent
                                        sx={{
                                            marginBottom: 5,
                                            border: "1px solid #d6d5d5",
                                        }}
                                        themeColor={themeColor}
                                        question={
                                            "Qué lástima, porque hoy tenemos ofertas especiales que acaban pronto…\n\r \n\r¿Quieres conocerlas?"
                                        }
                                        type={"offerType"}
                                        handleEnd={handleEnd}
                                        handleOffer={handleOffer}
                                    />
                                )}

                                {offerStep === 3 && (
                                    <CardComponent
                                        sx={{
                                            marginBottom: 5,
                                            border: "1px solid #d6d5d5",
                                        }}
                                        themeColor={themeColor}
                                        question={
                                            "Gracias por tu atención, puedes recibir novedades de ofertas por email."
                                        }
                                        type={"offerType"}
                                        sendMail={handleOffer}
                                        isOfferEmailValid={isOfferEmailValid}
                                        offerEmail={offerEmail}
                                        setOfferEmail={setOfferEmail}
                                    />
                                )}
                            </>
                        )} */}

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                py: "8px",
                fontSize: "13px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                textAlign: "left",
                lineHeight: "16px",
                zIndex: 3,
              }}
            >
              <Typography px={1} variant="caption">
                Las mejores ofertas de telefonía, al alcance de 1 click
              </Typography>
            </Box>

            <Box id="trama-image" />

            <Box
              sx={{
                position: "absolute",
                top: 40,
                left: 20,
                zIndex: 3,
              }}
            >
              <IconButton color="inherit" onClick={() => setMuted(!muted)}>
                {muted ? (
                  <VolumeOffIcon sx={{ color: "white" }} />
                ) : (
                  <VolumeUpIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            </Box>

            {loading && (
              <PageLoader loadingMsg={loadingMsg} themeColor={themeColor} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
